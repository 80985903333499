/**
 * Creates a debounced version of a function that will only execute after a specified delay has passed since the last time it was invoked.
 * If immediate is set to true, the function will be executed immediately the first time it is invoked.
 *
 * @param fn - The function to be debounced.
 * @param [wait=250] - The amount of time in milliseconds to wait before executing the debounced function.
 * @param [immediate=false] - If true, the function will be executed immediately the first time it is invoked.
 * @returns - The debounced function.
 */
export default function useDebounce<Fn extends (...args: unknown[]) => unknown>(
  fn: Fn,
  wait = 250,
  immediate = false
) {
  let timeout: NodeJS.Timeout | undefined
  let immediated = false

  function debounced(...args: Parameters<Fn>) {
    const later = () => {
      timeout = undefined
      fn(...args)
    }

    clearTimeout(timeout)

    if (!immediated && immediate) {
      fn(...args)
      immediated = true;
    } else {
      timeout = setTimeout(later, wait)
    }
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
  }

  return debounced
}
